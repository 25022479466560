<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="logo" />
			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="currency"
				:form-errors="formErrors['amount']"
				@set-button-value="setButtonValue"
			/>

			<div class="small-width">
				<promocode
					:form-promo-value="form.promocode"
					:form-error="formErrors['promocode']"
					@set-promo-value="setPromoValue"
				/>
			</div>

			<button type="button" class="button primary-button deposit center" @click="payWithDusupay()">
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositDusupayGeneric',
	components: {
		PageLayout,
		loader,
		amountButtons,
		promocode,
		paymentLogo,
		paymentWallet,
	},
	props: ['providerId', 'method', 'logo', 'methodId'],
	data() {
		return {
			currency: 'USD',
			form: {
				amount: null,
				promocode: null,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			paymentOptions: null,
			loading: false,
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['getUser']),
		country() {
			return this.getUser.country;
		},
		currentMethod() {
			return this.$store.getters.getCurrentMethod(this.methodId);
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		async payWithDusupay() {
			if (!this.form.amount) {
				return;
			}

			this.loading = true;
			try {
				const currency = 'USD';

				try {
					const response = await apiClient.payWithDusupay(
						this.walletId,
						currency,
						this.form.amount,
						this.method,
						this.providerId,
						this.form.promocode
					);
					this.loading = false;
					if (response.status === 200 && response.data?.payment_url) {
						Analytics.trackEvent({
							event: 'Deposit',
							value: this.form.amount,
							label: `Dusupay_${this.method}_${this.providerId}`,
						});

						window.location.replace(response.data.payment_url);
					} else {
						console.error(response);
					}
				} catch (error) {
					this.loading = false;
					console.error(error);
					const walletId = this.$route.params.walletId;

					this.$router.push({
						path: `/payments/deposit/${walletId}/confirmation`,
						query: {
							status: 'error',
							provider: 'Dusupay',
							providerId: this.providerId,
							method: this.method,
						},
					});
				}
			} catch (e) {
				console.error(e);
				const walletId = this.$route.params.walletId;

				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
			}
		},
	},
};
</script>
<style scoped>
button.btn-submit {
	margin-top: 35px;
}
</style>
